import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogTitle, TextField, DialogContent, Autocomplete, IconButton, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import Transition from "../../components/Transition";
import { useDispatch, useSelector } from "react-redux";
import { registerCompany } from '../../redux/actions/auth';
import styles from './Vacancy.module.scss';
import img1 from "../../assets/images/vacancy/Features-01.png";
import img2 from "../../assets/images/vacancy/Features-02.png";
import img3 from "../../assets/images/vacancy/Features-03.png";
import img4 from "../../assets/images/vacancy/Features-04.png";
import img5 from "../../assets/images/vacancy/Features-05.png";
import img6 from "../../assets/images/vacancy/Features-06.png";
import img7 from "../../assets/images/vacancy/Features-07.png";
import img8 from "../../assets/images/vacancy/Features-08.png";
import img9 from "../../assets/images/vacancy/Features-09.png";
import img10 from "../../assets/images/vacancy/Features-10.png";
import img11 from "../../assets/images/vacancy/Features-11.png";
import img12 from "../../assets/images/vacancy/Features-12.png";
import img13 from "../../assets/images/vacancy/Features-13.png";
import img14 from "../../assets/images/vacancy/Features-14.png";
import img15 from "../../assets/images/vacancy/Features-15.png";
import img16 from "../../assets/images/vacancy/Features-16.png";

import CircleIcon from '@mui/icons-material/Circle';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { toast } from "react-toastify";
import { FileUploader } from "react-drag-drop-files";
import { getPartnersCompany } from '../../redux/actions/app';

const fileTypes = ["JPG", "PNG"];

const Vacancy = () => {
    const inputRef = useRef();
    const dispatch = useDispatch();
    const isMobile = window.innerWidth < 756;
    const [infoDialog, setInfoDialog] = useState(false);
    const [slide, setSlide] = useState(1);
    const [loadingRegister, setLoadingRegister] = useState();
    const [successMsg, setSuccessMsg] = useState(false);

    const partners = useSelector((state) => state.app.partners);
    const [clientIndex, setClientIndex] = useState(0);
    const categories = useSelector((state) => state.app.business_categories);
    const countries = useSelector((state) => state.app.countries);
    const [cities, setCities] = useState([]);
    const [errors, setErrors] = useState(false);

    const [name, setName] = useState();
    const [number, setNumber] = useState();
    const [category, setCategory] = useState();
    const [country, setCountry] = useState();
    const [city, setCity] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [logo1, setLogo1] = useState();
    const [logo2, setLogo2] = useState();
    const [terms, setTerms] = useState(false);

    const goToHrBee = () => {
        setInfoDialog(false);
        window.open(`${process.env.REACT_APP_HR_BEE_MANAGEMENT_URL}/auth/login`, '_blank');
    }

    const onChooseImg = () => {
        inputRef.current.click();
    };

    const handleChangeFile = (file) => {
        setLogo1(file);
        setLogo2(URL.createObjectURL(file));
    };

    useEffect(() => {
        if(country){
            countries.forEach(element => {
                if(element.country_iso === country){
                    setCities(element.cities);
                }
            });
        }
    }, [country, countries]);

    useEffect(() => {
        dispatch(getPartnersCompany());
    }, [])

    const onRegisterFormHandler = (e) => {
        e.preventDefault();
        if(password === confirmPassword){
            setErrors(false);
            setLoadingRegister(true);   
            const cityFound = cities.find(c => c.name === city);
            const countryFound = countries.find(c => c.country_name === country);
            const cat = categories.find(c => c.sq === category);
            const data = new FormData();
            data.append('name', name);
            data.append('category', cat.value);
            data.append('fiscal_number', number);
            data.append('country_name', country);
            data.append('country_value', countryFound.country_iso);
            data.append('city_name', city);
            data.append('city_value', cityFound.value);
            data.append('email', email);
            data.append('password', password);
            data.append('password_confirmation', confirmPassword);
            data.append('logo1', logo1);
            data.append('user_type', 'company');
            dispatch(registerCompany(data)).then(res => {
                toast.success("Ju jeni regjistruar në HrBee!");
                setSuccessMsg(true);
            }).catch(err => {
                if(err.data.message === "email_alredy_exist"){
                    toast.error("Ky email egziston. Ju lutem provoni të kyqeni!");
                }else{
                    toast.error("Diçka shkoi keq. Ju lutem provoni përsëri!");
                }
                setLoadingRegister(false);
            });   
        }else{
            setErrors(true);
        }
    };

    const closeDialog = () => {
        setInfoDialog(false);
        setSuccessMsg(false);
        setName();
        setCategory();
        setCity();
        setCountry();
        setNumber();
        setTerms(false);
        setEmail();
        setPassword();
        setConfirmPassword();
        setLogo1();
        setLogo2();
    }

    return (
        <div>
            <div className='container my-5'>
                <div className='row align-items-center'>
                    <div className='col-md-9 text-center'>
                        <img alt="img" src={slide === 1 ? img1 : slide === 2 ? img2 : slide === 3 ? img3 : slide === 4 ? img4 : slide === 5 ? img5 : slide === 6 ? img6 : slide === 7 ? img7 : slide === 8 ? img8 : slide === 9 ? img9 : slide === 10 ? img10 : slide === 11 ? img11 : slide === 12 ? img12 : slide === 13 ? img13 : slide === 14 ? img14 : slide === 15 ? img15 : img16} width={isMobile ? "100%" : "100%"} />
                        <div className='d-flex justify-content-center align-items-center mb-4'>
                            <IconButton disabled={slide === 1} onClick={() => setSlide(slide - 1)}>
                                <KeyboardArrowLeftIcon fontSize='large' />
                            </IconButton>
                            {!isMobile && (
                                <>
                                    <CircleIcon className='mx-1' fontSize='10' color={slide === 1 ? "primary" : "secondary"} />
                                    <CircleIcon className='mx-1' fontSize='10' color={slide === 2 ? "primary" : "secondary"} />
                                    <CircleIcon className='mx-1' fontSize='10' color={slide === 3 ? "primary" : "secondary"} />
                                    <CircleIcon className='mx-1' fontSize='10' color={slide === 4 ? "primary" : "secondary"} />
                                    <CircleIcon className='mx-1' fontSize='10' color={slide === 5 ? "primary" : "secondary"} />
                                    <CircleIcon className='mx-1' fontSize='10' color={slide === 6 ? "primary" : "secondary"} />
                                    <CircleIcon className='mx-1' fontSize='10' color={slide === 7 ? "primary" : "secondary"} />
                                    <CircleIcon className='mx-1' fontSize='10' color={slide === 8 ? "primary" : "secondary"} />
                                    <CircleIcon className='mx-1' fontSize='10' color={slide === 9 ? "primary" : "secondary"} />
                                    <CircleIcon className='mx-1' fontSize='10' color={slide === 10 ? "primary" : "secondary"} />
                                    <CircleIcon className='mx-1' fontSize='10' color={slide === 11 ? "primary" : "secondary"} />
                                    <CircleIcon className='mx-1' fontSize='10' color={slide === 12 ? "primary" : "secondary"} />
                                    <CircleIcon className='mx-1' fontSize='10' color={slide === 13 ? "primary" : "secondary"} />
                                    <CircleIcon className='mx-1' fontSize='10' color={slide === 14 ? "primary" : "secondary"} />
                                    <CircleIcon className='mx-1' fontSize='10' color={slide === 15 ? "primary" : "secondary"} />
                                    <CircleIcon className='mx-1' fontSize='10' color={slide === 16 ? "primary" : "secondary"} />
                                </>
                            )}
                            <IconButton disabled={slide === 16} onClick={() => setSlide(slide + 1)}>
                                <KeyboardArrowRightIcon fontSize='large' />
                            </IconButton>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className={styles.CardVacancy}>

                            <h4 className={styles.Title}>Menaxhimi i rekrutimit</h4>

                            <div className={styles.Divider}></div>

                            <p>- Krijimi dhe editimi i <span>shpalljeve</span></p>
                            <p>- <span>Standartizimi</span> i shpalljeve</p>
                            <p>- <span>Shpallja</span> e konkurseve</p>
                            <p>- <span>Seletimi</span> i CV-ve të standartizuara</p>
                            <p>- Menaxhimi i <span>Procesit</span> të intervistimit</p>
                            <p>- <span>Orari i intervistimit</span> të kandidatëve</p>
                            <p>- <span>Vlerësimi</span> i kandidatëve</p>
                            <p>- <span>Përzgjedhja</span> e kandidatëve</p>

                            <div className={styles.Divider}></div>

                            <p>- Shpallja aktive nga <span>1</span> deri <span>30 ditë</span></p>
                            <p>- Shpërndarja në <span>rrjete sociale</span></p>
                            <p>- Mbi <span>30+</span> procese të <span>dixhitalizuara</span></p>
                            <p>- Rekrutimi është për <span>50x</span> më i shpejtë krahasuar me portalet e tjera</p>
                            <p>- Ruajtja e <span>shablloneve</span> të konkurseve</p>
                            <p>- Shpërndarje e shpalljeve edhe në <span>portalet e tjera</span> ndërsa <span>menaxhimi</span> përmes platformës tonë.</p>

                            <div className={styles.Divider}></div>

                            <div className='text-center'>
                                <p>Çmimi për shpallje</p>
                                <p className={styles.Price}>19.00 €<span> / 99.00 €</span></p>
                            </div>

                            <div className={styles.Divider}></div>

                            <div className='text-center'>
                                <button className={styles.ApplyButtonModal} onClick={() => setInfoDialog(true)}>
                                    Vazhdo
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {partners && partners.length > 0 && (
                <div className='container'>
                    <h4 className={styles.PartnerTitle}>Kompanitë që përdorin platformën tonë</h4>
                    <div className={styles.PartnersWrapper}>
                        {partners.map((partner, idx) => (
                            <div className='text-center'>
                                <img src={`${process.env.REACT_APP_API_PATH}/images-files/${partner.logo_url}`} alt="" />
                            </div>
                        ))}
                    </div>
                </div>
            )}

            <Dialog
                open={infoDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => closeDialog()}
                aria-describedby="alert-dialog-slide-description"
                className={styles.DialogRegister}
            >
                {!successMsg && (
                    <DialogTitle align="center">
                        <p>Ju lutem plotësoni të dhënat e biznesit tuaj për të hapur llogarinë tuaj,</p>
                        <p>në mënyrë që ju pastaj të mund t'i postoni shpalljet tuaj.</p>
                    </DialogTitle>
                )}
                <DialogContent>
                    {successMsg ? (
                        <div className={styles.SuccessMsgWrapper}>
                            <div className='text-center my-5'>
                                <p className={styles.PrimaryText}>***Njoftim***</p>
                                <p>Ju keni <span className={styles.PrimaryText}>pranuar një email</span> nga hr bee llc, përmes të cilës</p>
                                <p>ju duhet ta verifikoni llogarinë tuaj.</p>
                            </div>
                            <div className='text-center my-5'>
                                <p className={styles.DangerText}>!!!Vëmendje!!!</p>
                                <p>Ju lutem kontrolloni <span className={styles.DangerText}>Spam Junk Mail</span> pasi që kohë pas kohe email</p>
                                <p>mund të shkojnë aty.</p>
                                <p className='mt-3'>Ju faleminderit!</p>
                            </div>
                        </div>
                    ) : (
                        <form onSubmit={onRegisterFormHandler}>
                            <TextField 
                                id="outlined-basic" 
                                size="small" 
                                variant="outlined" 
                                onChange={e => setName(e.target.value)} 
                                className="my-2" 
                                value={name} 
                                label="Emri i biznesit" 
                                fullWidth
                                required
                            />
                            <div className='row'>
                                <div className='col-md-6'>
                                    <TextField 
                                        id="outlined-basic" 
                                        size="small" 
                                        variant="outlined" 
                                        onChange={e => setNumber(e.target.value)} 
                                        className="my-2" 
                                        value={number} 
                                        label="UNI i biznesit" 
                                        fullWidth
                                        required
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        size='small'
                                        options={categories.map((option) => option.sq)}
                                        value={category}
                                        onChange={(e, newValue) => setCategory(newValue)}
                                        renderInput={(params) => <TextField {...params} className="my-2" fullWidth label="Kategoria" required />}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        size='small'
                                        options={countries.map((option) => option.country_name)}
                                        value={country}
                                        onChange={(e, newValue) => setCountry(newValue)}
                                        renderInput={(params) => <TextField {...params} className="my-2" fullWidth label="Shteti" required />}
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <Autocomplete
                                        disablePortal
                                        disabled={!country}
                                        id="combo-box-demo"
                                        size='small'
                                        options={cities.map((option) => option.name)}
                                        value={city}
                                        onChange={(e, newValue) => setCity(newValue)}
                                        renderInput={(params) => <TextField {...params} fullWidth className="my-2" label="Qyteti" required />}
                                    />
                                </div>
                            </div>
                            <TextField 
                                id="outlined-basic" 
                                size="small" 
                                type="email"
                                variant="outlined" 
                                onChange={e => setEmail(e.target.value)} 
                                className="my-2" 
                                value={email} 
                                label="E-mail" 
                                fullWidth
                                required
                            />
                            <div className='row'>
                                <div className='col-md-6'>
                                    <TextField 
                                        id="outlined-basic" 
                                        size="small" 
                                        type="password"
                                        variant="outlined" 
                                        onChange={e => setPassword(e.target.value)} 
                                        className="my-2" 
                                        value={password} 
                                        label="Fjalëkalimi" 
                                        fullWidth
                                        required
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <TextField
                                        size="small"
                                        type="password"
                                        variant="outlined"
                                        fullWidth
                                        label="Konfirmo fjalëkalimin"
                                        name="confirmPassword"
                                        className="my-2" 
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        required
                                        error={errors && password !== confirmPassword}
                                        helperText={(errors && password !== confirmPassword && "Fjalëkalimet nuk përputhen")}
                                    />
                                </div>
                            </div>
                            <div className='text-center'>
                                {logo2 && (
                                    <img alt="Logo" src={logo2} style={{ maxWidth: '150px', maxHeight: '150px', borderRadius: '10px' }} />
                                )}
                            </div>
                            <div className='row'>
                                <div className='col-md-2 my-2'>
                                </div>
                                <div className='col-md-4 my-2'>
                                    <FileUploader label="Logo e biznesit" handleChange={handleChangeFile} name="file" types={fileTypes} />
                                </div>
                                <div className='col-md-2 my-2'>
                                </div>
                            </div>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox required size='small' checked={terms} onChange={(e) => setTerms(e.target.checked)} />} label={<div className={styles.CheckboxLabel}>Unë i kam lexuar dhe pranuar <span>termat dhe kushtet</span> dhe jam <span>abonuar</span> në buletinin e njoftimeve</div>} />
                            </FormGroup>
                            <div className='text-center'>
                                <button className={styles.RegisterButton} type="submit">
                                    Krijo llogarinë
                                </button>
                            </div>
                            <div className={styles.AlreadyAccount}>
                                Unë kam tashmë një llogari dhe dua të <span style={{ cursor: 'pointer' }} onClick={() => goToHrBee()}>kyçem</span>
                            </div>
                        </form>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Vacancy;