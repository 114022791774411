import React, { useState, useEffect } from 'react';
import { MobileStepper, Button, TextField, InputLabel, FormControl, Select, MenuItem, Checkbox, FormControlLabel, Autocomplete } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { registerJobSeekerAndApply } from '../../redux/actions/auth';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import styles from './ApplyJobModal.module.scss';
import { toast } from "react-toastify";

const Register = (props) => {
    const dispatch = useDispatch();
    const { job, onClose } = props;

    const countries = useSelector((state) => state.app.countries);
    const languagesStore = useSelector((state) => state.app.languages);
    const languagesLevels = useSelector((state) => state.app.languages_levels);
    const skillsLevels = useSelector((state) => state.app.skills_levels);
    const drivingLicenses = useSelector((state) => state.app.driving_licenses);

    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(false);
    const [cities, setCities] = useState([]);

    const [name, setName] = useState();
    const [surname, setSurname] = useState();
    const [country, setCountry] = useState();
    const [city, setCity] = useState();
    const [phone, setPhone] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();

    const [title, setTitle] = useState();
    const [biography, setBiography] = useState();
    const [linkedin, setLinkedin] = useState();

    const [experiences, setExperiences] = useState(
        [{ company_name: "", position: "", join_date: "", leave_date: "", still_working: false, country: "", city: "", description: "<p>Përshkrimi i punës...</p>" }]
    );
    const [educations, setEducations] = useState(
        [{ school: "", degree: "", field_of_study: "", from_date: "", to_date: "", ongoing: false, country: "", city: "" }]
    );
    const [trainings, setTrainings] = useState(
        [{ company_name: "", position: "", join_date: "", leave_date: "", still_working: false, country: "", city: "" }]
    );
    const [languages, setLanguages] = useState(
        [{ language_value: "", reading: "", listening: "", speaking: "", writing: "" }]
    );
    const [computerSkills, setComputerSkills] = useState(
        [{ skill_name: "", skill_value: "", level: "", type: "IT" }]
    );
    const [otherSkills, setOtherSkills] = useState(
        [{ skill_name: "", skill_value: "", level: "", type: "other" }]
    );
    const [licenses, setLicenses] = useState(
        [{ driving_category: "" }]
    );

    // LICENSES
    const handleChangeLicenses= (i, name, value) => {
        let license = [...licenses];
        license[i][name] = value;
        setLicenses(license);
    }
    const addNewLicenseHandler = () => {
        setLicenses([...licenses, { driving_category: "" }])
    }    
    const removeLicenseHandler = (i) => {
        let license = [...licenses];
        license.splice(i, 1);
        setLicenses(license)
    }

    // Other Skills
    const handleChangeOtherSkills = (i, name, value) => {
        let otherSkill = [...otherSkills];
        if(name === "skill_name"){
            otherSkill[i]["skill_value"] = value;
        }
        otherSkill[i][name] = value;
        setOtherSkills(otherSkill);
        console.log("others", otherSkills);
    }
    const addNewOtherSkillHandler = () => {
        setOtherSkills([...otherSkills, { skill_name: "", skill_value: "", level: "", type: "other" }])
    }    
    const removeOtherSkillHandler = (i) => {
        let otherSkillse = [...otherSkills];
        otherSkillse.splice(i, 1);
        setOtherSkills(otherSkillse)
    }

    // Computer Skills
    const handleChangeComputerSkills = (i, name, value) => {
        let computerSkill = [...computerSkills];
        if(name === "skill_name"){
            computerSkill[i]["skill_value"] = value;
        }
        computerSkill[i][name] = value;
        setComputerSkills(computerSkill);
        console.log("computer", computerSkills);
    }
    const addNewComputerSkillHandler = () => {
        setComputerSkills([...computerSkills, { skill_name: "", skill_value: "", level: "", type: "IT" }])
    }    
    const removeComputerSkillHandler = (i) => {
        let computerSkillse = [...computerSkills];
        computerSkillse.splice(i, 1);
        setComputerSkills(computerSkillse)
    }

    // Languages
    const handleChangeLanguages = (i, name, value) => {
        let newLanguage = [...languages];
        newLanguage[i][name] = value;
        setLanguages(newLanguage);
    }
    const addNewLanguageHandler = () => {
        setLanguages([...languages, { language_value: "", reading: "", listening: "", speaking: "", writing: "" }])
    }    
    const removeLanguageHandler = (i) => {
        let newLanguages = [...languages];
        newLanguages.splice(i, 1);
        setLanguages(newLanguages)
    }

    // Trainings
    const handleChangeTrainings = (i, name, value) => {
        let newTraining = [...trainings];
        newTraining[i][name] = value;
        setTrainings(newTraining);
    }
    const addNewTrainingHandler = () => {
        setTrainings([...trainings, { company_name: "", position: "", join_date: "", leave_date: "", still_working: false, country: "", city: "" }])
    }    
    const removeTrainingHandler = (i) => {
        let newTrainings = [...trainings];
        newTrainings.splice(i, 1);
        setTrainings(newTrainings)
    }

    // Educations
    const handleChangeEducations = (i, name, value) => {
        let newEducations = [...educations];
        newEducations[i][name] = value;
        setEducations(newEducations);
    }
    const addNewEducationHandler = () => {
        setEducations([...educations, { school: "", degree: "", field_of_study: "", from_date: "", to_date: "", ongoing: false, country: "", city: "" }])
    }    
    const removeEducationHandler = (i) => {
        let newEducations = [...educations];
        newEducations.splice(i, 1);
        setEducations(newEducations)
    }

    // Experiences
    const handleChangeExperiences = (i, name, value) => {
        let newExperience = [...experiences];
        newExperience[i][name] = value;
        setExperiences(newExperience);
    }
    const addNewExperienceHandler = () => {
        setExperiences([...experiences, { company_name: "", position: "", join_date: "", leave_date: "", still_working: false, country: "", city: "", description: "<p>Përshkrimi i punës...</p>" }])
    }    
    const removeExperienceHandler = (i) => {
        let newExperiences = [...experiences];
        newExperiences.splice(i, 1);
        setExperiences(newExperiences)
    }

    const handleNext = (e) => {
        e.preventDefault();
        if(activeStep === 8){
            submitData();
        }else{
            if(canGoNext()){
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }else{
                setErrors(true);
            }
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    useEffect(() => {
        if(country){
            countries.forEach(element => {
                if(element.country_iso === country){
                    setCities(element.cities);
                }
            });
        }
    }, [country, countries]);

    const canGoNext = () => {
        if(password !== confirmPassword){
            return false;
        }
        return true;
    };

    const submitData = () => {
        setLoading(true);
        const cityFound = cities.find(c => c.name === city);
        const countryFound = countries.find(c => c.country_name === country);
        const data = {
            "name": name,
            "surname": surname,
            "country_name": country,
            "country_value": countryFound.country_iso,
            "city_name": city,
            "city_value": cityFound.value,
            "phone": phone,
            "email": email,
            "password": password,
            "confirm_password": confirmPassword,

            "title": title,
            "biography": biography,
            "linkedin": linkedin,

            "experiences": experiences,
            "educations": educations,
            "trainings": trainings,
            "languages": languages,
            "computerSkills": computerSkills,
            "otherSkills": otherSkills,
            "licenses": licenses,

            "job_id": job.job_id,
            "company_id": job.company_id
        };
        dispatch(registerJobSeekerAndApply(data)).then(res => {
            toast.success("Ju jeni regjistruar në HrBee dhe aplikimi për punë i është dërguar kompanisë!");
            onClose();
            setLoading(false);
        }).catch(err => {
            if(err.data.message === "email_alredy_exist"){
                toast.error("Ky email egziston. Ju lutem provoni të kyqeni!");
            }else{
                toast.error("Diçka shkoi keq. Ju lutem provoni përsëri!");
            }
            setLoading(false);
        });
    }

    return (
        <div>
            {activeStep === 0 && (
                <div>
                    <p className={styles.TextDescription}>Ju lutem plotësoni formën që të regjistroheni dhe krijoni profilin tuaj</p>
                    <p className={styles.TextDescription}>Aplikimi do ti shkojë kompanisë përmes platformës tonë</p>
                </div>
            )}
            {activeStep === 1 && (
                <div>
                    <p className={styles.TextDescription}>Ju mund të plotësoni disa të dhëna personale, në mënyrë që kompania të mësojë më shumë për ju</p>
                </div>
            )}
            {activeStep === 2 && (
                <div>
                    <p className={styles.TextDescription}>Përvoja e punës</p>
                </div>
            )}
            {activeStep === 3 && (
                <div>
                    <p className={styles.TextDescription}>Edukimi</p>
                </div>
            )}
            {activeStep === 4 && (
                <div>
                    <p className={styles.TextDescription}>Trajnime | Kurse</p>
                </div>
            )}
            {activeStep === 5 && (
                <div>
                    <p className={styles.TextDescription}>Gjuhët</p>
                </div>
            )}
            {activeStep === 6 && (
                <div>
                    <p className={styles.TextDescription}>Aftësitë kompjuterike</p>
                </div>
            )}
            {activeStep === 7 && (
                <div>
                    <p className={styles.TextDescription}>Aftësitë tjera</p>
                </div>
            )}
            {activeStep === 8 && (
                <div>
                    <p className={styles.TextDescription}>Licensat</p>
                </div>
            )}
            <form onSubmit={handleNext}>
                <div className={styles.RegisterForm}>
                    {/* Account Info */}
                    {activeStep === 0 && (
                        <div>
                            <div className='row'>
                                <div className='col-md-6 mt-3'>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        label="Emri"
                                        name="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className='col-md-6 mt-3'>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        label="Mbeimri"
                                        name="surname"
                                        value={surname}
                                        onChange={(e) => setSurname(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 mt-3'>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        size='small'
                                        options={countries.map((option) => option.country_name)}
                                        value={country}
                                        onChange={(e, newValue) => setCountry(newValue)}
                                        renderInput={(params) => <TextField {...params} label="Shteti" required />}
                                    />
                                    {/* <FormControl variant="outlined" fullWidth={true} size="small" error={errors && !country}>
                                        <InputLabel id="demo-simple-select-outlined-label">Shteti</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            label="Shteti"
                                            name="country"
                                            value={country}
                                            onChange={(e) => setCountry(e.target.value)}
                                        >
                                            {countries && countries.map(country => (
                                                <MenuItem key={country.country_iso} value={country.country_iso}>
                                                    {country.country_name}
                                                </MenuItem>     
                                            ))}                     
                                        </Select>
                                        {errors && !country && (
                                            <FormHelperText>
                                                Kjo fushë është e domosdoshme
                                            </FormHelperText>
                                        )}
                                    </FormControl> */}
                                </div>
                                <div className='col-md-6 mt-3'>
                                    <Autocomplete
                                        disablePortal
                                        disabled={!country}
                                        id="combo-box-demo"
                                        size='small'
                                        options={cities.map((option) => option.name)}
                                        value={city}
                                        onChange={(e, newValue) => setCity(newValue)}
                                        renderInput={(params) => <TextField {...params} label="Qyteti" required />}
                                    />
                                    {/* <FormControl variant="outlined" fullWidth={true} size="small" error={errors && !city}>
                                        <InputLabel id="demo-simple-select-outlined-label">Qyteti</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            label="Qyteti"
                                            name="city"
                                            disabled={!country}
                                            value={city}
                                            onChange={(e) => setCity(e.target.value)}
                                        >
                                            {cities && cities.map(city => (
                                                <MenuItem key={city.value} value={city.value}>
                                                    {city.name}
                                                </MenuItem>     
                                            ))}                     
                                        </Select>
                                        {errors && !city && (
                                            <FormHelperText>
                                                Kjo fushë është e domosdoshme
                                            </FormHelperText>
                                        )}
                                    </FormControl> */}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 mt-3'>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        label="Nr Telefonit"
                                        name="phone"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className='col-md-6 mt-3'>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        label="E-mail"
                                        name="email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 mt-3'>
                                    <TextField
                                        size="small"
                                        type="password"
                                        variant="outlined"
                                        fullWidth
                                        label="Fjalëkalimi"
                                        name="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className='col-md-6 mt-3'>
                                    <TextField
                                        size="small"
                                        type="password"
                                        variant="outlined"
                                        fullWidth
                                        label="Konfirmo fjalëkalimin"
                                        name="confirmPassword"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        required
                                        error={errors && password !== confirmPassword}
                                        helperText={(errors && password !== confirmPassword && "Fjalëkalimet nuk përputhen")}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {/* Personal Info */}
                    {activeStep === 1 && (
                        <div>
                            <div className='row'>
                                <div className='col-md-12 mt-3'>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        label="Titulli i pozitës"
                                        name="title"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12 mt-3'>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        label="Biografi e shkurtër"
                                        name="biography"
                                        value={biography}
                                        onChange={(e) => setBiography(e.target.value)}
                                        rows={4}
                                        multiline
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12 mt-3'>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        label="Linkedin URL"
                                        name="linkedin"
                                        value={linkedin}
                                        onChange={(e) => setLinkedin(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {/* Experience */}
                    {activeStep === 2 && (
                        <div>
                            {experiences && experiences.map((element, index) => (
                                <div>
                                    <div className='row'>
                                        <div className='col-md-6 mt-3'>
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                                label="Emri i kompanisë"
                                                name="company_name"
                                                value={element.company_name}
                                                onChange={e => handleChangeExperiences(index, "company_name", e.target.value)}
                                            />
                                        </div>
                                        <div className='col-md-6 mt-3'>
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                                label="Pozita"
                                                name="position"
                                                value={element.position}
                                                onChange={e => handleChangeExperiences(index, "position", e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4 mt-3'>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                size="small"
                                                type="date"
                                                variant="outlined"
                                                fullWidth
                                                label="Data e fillimit"
                                                name="join_date"
                                                value={element.join_date}
                                                onChange={e => handleChangeExperiences(index, "join_date", e.target.value)}
                                            />
                                        </div>
                                        <div className='col-md-4 mt-3'>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                size="small"
                                                type="date"
                                                variant="outlined"
                                                fullWidth
                                                label="Data e mbarimit"
                                                name="leave_date"
                                                value={element.leave_date}
                                                onChange={e => handleChangeExperiences(index, "leave_date", e.target.value)}
                                            />
                                        </div>
                                        <div className='col-md-4 mt-3'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={element.still_working}
                                                        onChange={e => handleChangeExperiences(index, "still_working", e.target.checked)}
                                                        name="still_working"
                                                        color="primary"
                                                        size="small"
                                                    />
                                                }
                                                label="Prezent"
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6 mt-3'>
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                                label="Shteti"
                                                name="country"
                                                value={element.country}
                                                onChange={e => handleChangeExperiences(index, "country", e.target.value)}
                                            />
                                        </div>
                                        <div className='col-md-6 mt-3'>
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                                label="Qyteti"
                                                name="city"
                                                value={element.city}
                                                onChange={e => handleChangeExperiences(index, "city", e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-12 mt-3">
                                            <CKEditor
                                                editor={ClassicEditor}
                                                data={element.description}
                                                onChange={(e, editor) => handleChangeExperiences(index, "description", editor.getData())}
                                                config={{         
                                                    toolbar: ['heading', '|', 'bold', 'italic', 'numberedList', 'bulletedList']
                                                }}  
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.AddNewFormButtons}>
                                        <div className={styles.ButtonText} onClick={() => addNewExperienceHandler()}>
                                            <AddCircleIcon fontSize="small" color="success"/><span>Shto përvojë pune</span>
                                        </div>                                                              
                                        {
                                            index ? (
                                                <div className={styles.ButtonText} onClick={() => removeExperienceHandler(index)}>
                                                    <RemoveCircleIcon className='ms-3' fontSize="small" /><span>Fshije</span>
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    {/* Education */}
                    {activeStep === 3 && (
                        <div>
                            {educations && educations.map((element, index) => (
                                <div>
                                    <div className='row'>
                                        <div className='col-md-12 mt-3'>
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                                label="Shkolla"
                                                name="school"
                                                required
                                                value={element.school}
                                                onChange={e => handleChangeEducations(index, "school", e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6 mt-3'>
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                                label="Niveli, BA, MA"
                                                name="degree"
                                                required
                                                value={element.degree}
                                                onChange={e => handleChangeEducations(index, "degree", e.target.value)}
                                            />
                                        </div>
                                        <div className='col-md-6 mt-3'>
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                                label="Drejtimi"
                                                required
                                                name="field_of_study"
                                                value={element.field_of_study}
                                                onChange={e => handleChangeEducations(index, "field_of_study", e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4 mt-3'>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                size="small"
                                                type="date"
                                                variant="outlined"
                                                fullWidth
                                                label="Data e fillimit"
                                                name="from_date"
                                                required
                                                value={element.from_date}
                                                onChange={e => handleChangeEducations(index, "from_date", e.target.value)}
                                            />
                                        </div>
                                        <div className='col-md-4 mt-3'>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                size="small"
                                                type="date"
                                                variant="outlined"
                                                fullWidth
                                                label="Data e mbarimit"
                                                name="to_date"
                                                value={element.to_date}
                                                onChange={e => handleChangeEducations(index, "to_date", e.target.value)}
                                            />
                                        </div>
                                        <div className='col-md-4 mt-3'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={element.ongoing}
                                                        onChange={e => handleChangeEducations(index, "ongoing", e.target.checked)}
                                                        name="ongoing"
                                                        color="primary"
                                                        size="small"
                                                    />
                                                }
                                                label="Nuk kam përfunduar"
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6 mt-3'>
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                                label="Shteti"
                                                required
                                                name="country"
                                                value={element.country}
                                                onChange={e => handleChangeEducations(index, "country", e.target.value)}
                                            />
                                        </div>
                                        <div className='col-md-6 mt-3'>
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                                label="Qyteti"
                                                name="city"
                                                required
                                                value={element.city}
                                                onChange={e => handleChangeEducations(index, "city", e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.AddNewFormButtons}>
                                        <div className={styles.ButtonText} onClick={() => addNewEducationHandler()}>
                                            <AddCircleIcon fontSize="small" color="success"/><span>Shto edukimin</span>
                                        </div>                                                              
                                        {
                                            index ? (
                                                <div className={styles.ButtonText} onClick={() => removeEducationHandler(index)}>
                                                    <RemoveCircleIcon className='ms-3' fontSize="small" /><span>Fshije</span>
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    {/* Trainings */}
                    {activeStep === 4 && (
                        <div>
                            {trainings && trainings.map((element, index) => (
                                <div>
                                    <div className='row'>
                                        <div className='col-md-6 mt-3'>
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                                label="Emri i kompanisë"
                                                name="company_name"
                                                value={element.company_name}
                                                onChange={e => handleChangeTrainings(index, "company_name", e.target.value)}
                                            />
                                        </div>
                                        <div className='col-md-6 mt-3'>
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                                label="Trajnimi | Kursi"
                                                name="position"
                                                value={element.position}
                                                onChange={e => handleChangeTrainings(index, "position", e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4 mt-3'>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                size="small"
                                                type="date"
                                                variant="outlined"
                                                fullWidth
                                                label="Data e fillimit"
                                                name="join_date"
                                                value={element.join_date}
                                                onChange={e => handleChangeTrainings(index, "join_date", e.target.value)}
                                            />
                                        </div>
                                        <div className='col-md-4 mt-3'>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                size="small"
                                                type="date"
                                                variant="outlined"
                                                fullWidth
                                                label="Data e mbarimit"
                                                name="leave_date"
                                                value={element.leave_date}
                                                onChange={e => handleChangeTrainings(index, "leave_date", e.target.value)}
                                            />
                                        </div>
                                        <div className='col-md-4 mt-3'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={element.still_working}
                                                        onChange={e => handleChangeTrainings(index, "still_working", e.target.checked)}
                                                        name="still_working"
                                                        color="primary"
                                                        size="small"
                                                    />
                                                }
                                                label="Prezent"
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6 mt-3'>
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                                label="Shteti"
                                                name="country"
                                                value={element.country}
                                                onChange={e => handleChangeTrainings(index, "country", e.target.value)}
                                            />
                                        </div>
                                        <div className='col-md-6 mt-3'>
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                                label="Qyteti"
                                                name="city"
                                                value={element.city}
                                                onChange={e => handleChangeTrainings(index, "city", e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.AddNewFormButtons}>
                                        <div className={styles.ButtonText} onClick={() => addNewTrainingHandler()}>
                                            <AddCircleIcon fontSize="small" color="success"/><span>Shto trajnim | kurs</span>
                                        </div>                                                              
                                        {
                                            index ? (
                                                <div className={styles.ButtonText} onClick={() => removeTrainingHandler(index)}>
                                                    <RemoveCircleIcon className='ms-3' fontSize="small" /><span>Fshije</span>
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    {/* LANGUAGES */}
                    {activeStep === 5 && (
                        <div>
                            {languages && languages.map((element, index) => (
                                <div>
                                    <div className='row'>
                                        <div className='col-md-12 mt-3'>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                size='small'
                                                options={languagesStore.map((option) => option.name)}
                                                value={element.language_value}
                                                onChange={(e, newValue) => handleChangeLanguages(index, "language_value", newValue)}
                                                renderInput={(params) => <TextField {...params} label="Zgjedh gjuhën" />}
                                            />
                                            {/* <FormControl variant="outlined" fullWidth={true} size="small">
                                                <InputLabel id="demo-simple-select-outlined-label">Zgjedh gjuhën</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    label="Zgjedh gjuhën"
                                                    name="language_value"
                                                    value={element.language_value}
                                                    onChange={e => handleChangeLanguages(index, "language_value", e.target.value)}
                                                >
                                                    {languagesStore && languagesStore.map(language => (
                                                        <MenuItem key={language.name} value={language.name}>
                                                            {language.name}
                                                        </MenuItem>     
                                                    ))}                     
                                                </Select>
                                            </FormControl> */}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6 mt-3'>
                                            <FormControl variant="outlined" fullWidth={true} size="small">
                                                <InputLabel id="demo-simple-select-outlined-label">Të lexuarit</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    label="Të lexuarit"
                                                    name="reading"
                                                    value={element.reading}
                                                    onChange={e => handleChangeLanguages(index, "reading", e.target.value)}
                                                >
                                                    {languagesLevels && languagesLevels.map(language => (
                                                        <MenuItem key={language.value} value={language.value}>
                                                            {language.sq}
                                                        </MenuItem>     
                                                    ))}                     
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className='col-md-6 mt-3'>
                                            <FormControl variant="outlined" fullWidth={true} size="small">
                                                <InputLabel id="demo-simple-select-outlined-label">Të kuptuarit</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    label="Të kuptuarit"
                                                    name="listening"
                                                    value={element.listening}
                                                    onChange={e => handleChangeLanguages(index, "listening", e.target.value)}
                                                >
                                                    {languagesLevels && languagesLevels.map(language => (
                                                        <MenuItem key={language.value} value={language.value}>
                                                            {language.sq}
                                                        </MenuItem>     
                                                    ))}                     
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6 mt-3'>
                                            <FormControl variant="outlined" fullWidth={true} size="small">
                                                <InputLabel id="demo-simple-select-outlined-label">Të folurit</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    label="Të folurit"
                                                    name="speaking"
                                                    value={element.speaking}
                                                    onChange={e => handleChangeLanguages(index, "speaking", e.target.value)}
                                                >
                                                    {languagesLevels && languagesLevels.map(language => (
                                                        <MenuItem key={language.value} value={language.value}>
                                                            {language.sq}
                                                        </MenuItem>     
                                                    ))}                     
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className='col-md-6 mt-3'>
                                            <FormControl variant="outlined" fullWidth={true} size="small">
                                                <InputLabel id="demo-simple-select-outlined-label">Të shkruarit</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    label="Të shkruarit"
                                                    name="writing"
                                                    value={element.writing}
                                                    onChange={e => handleChangeLanguages(index, "writing", e.target.value)}
                                                >
                                                    {languagesLevels && languagesLevels.map(language => (
                                                        <MenuItem key={language.value} value={language.value}>
                                                            {language.sq}
                                                        </MenuItem>     
                                                    ))}                     
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className={styles.AddNewFormButtons}>
                                        <div className={styles.ButtonText} onClick={() => addNewLanguageHandler()}>
                                            <AddCircleIcon fontSize="small" color="success"/><span>Shto gjuhë</span>
                                        </div>                                                              
                                        {
                                            index ? (
                                                <div className={styles.ButtonText} onClick={() => removeLanguageHandler(index)}>
                                                    <RemoveCircleIcon className='ms-3' fontSize="small" /><span>Fshije</span>
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    {/* Cmputer Skills */}
                    {activeStep === 6 && (
                        <div>
                            {computerSkills && computerSkills.map((element, index) => (
                                <div>
                                    <div className='row'>
                                        <div className='col-md-6 mt-3'>
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                                label="Aftësi kompjuterike, React JS, PHP..."
                                                name="skill_name"
                                                value={element.skill_name}
                                                onChange={e => handleChangeComputerSkills(index, "skill_name", e.target.value)}
                                            />
                                        </div>
                                        <div className='col-md-6 mt-3'>
                                            <FormControl variant="outlined" fullWidth={true} size="small">
                                                <InputLabel id="demo-simple-select-outlined-label">Niveli</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    label="Niveli"
                                                    name="level"
                                                    value={element.level}
                                                    onChange={e => handleChangeComputerSkills(index, "level", e.target.value)}
                                                >
                                                    {skillsLevels && skillsLevels.map(level => (
                                                        <MenuItem key={level.value} value={level.value}>
                                                            {level.sq}
                                                        </MenuItem>     
                                                    ))}                     
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className={styles.AddNewFormButtons}>
                                        <div className={styles.ButtonText} onClick={() => addNewComputerSkillHandler()}>
                                            <AddCircleIcon fontSize="small" color="success"/><span>Shto aftësi kompjuterike</span>
                                        </div>                                                              
                                        {
                                            index ? (
                                                <div className={styles.ButtonText} onClick={() => removeComputerSkillHandler(index)}>
                                                    <RemoveCircleIcon className='ms-3' fontSize="small" /><span>Fshije</span>
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    {/* Other Skills */}
                    {activeStep === 7 && (
                        <div>
                            {otherSkills && otherSkills.map((element, index) => (
                                <div>
                                    <div className='row'>
                                        <div className='col-md-6 mt-3'>
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                                label="Aftësi tjera, Rekrutim, Planifikim strategjik..."
                                                name="skill_name"
                                                value={element.skill_name}
                                                onChange={e => handleChangeOtherSkills(index, "skill_name", e.target.value)}
                                            />
                                        </div>
                                        <div className='col-md-6 mt-3'>
                                            <FormControl variant="outlined" fullWidth={true} size="small">
                                                <InputLabel id="demo-simple-select-outlined-label">Niveli</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    label="Niveli"
                                                    name="level"
                                                    value={element.level}
                                                    onChange={e => handleChangeOtherSkills(index, "level", e.target.value)}
                                                >
                                                    {skillsLevels && skillsLevels.map(level => (
                                                        <MenuItem key={level.value} value={level.value}>
                                                            {level.sq}
                                                        </MenuItem>     
                                                    ))}                     
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className={styles.AddNewFormButtons}>
                                        <div className={styles.ButtonText} onClick={() => addNewOtherSkillHandler()}>
                                            <AddCircleIcon fontSize="small" color="success"/><span>Shto aftësi tjera</span>
                                        </div>                                                              
                                        {
                                            index ? (
                                                <div className={styles.ButtonText} onClick={() => removeOtherSkillHandler(index)}>
                                                    <RemoveCircleIcon className='ms-3' fontSize="small" /><span>Fshije</span>
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    {/* Licenses */}
                    {activeStep === 8 && (
                        <div>
                            {licenses && licenses.map((element, index) => (
                                <div>
                                    <div className='row'>
                                        <div className='col-md-12 mt-3'>
                                            <FormControl variant="outlined" fullWidth={true} size="small">
                                                <InputLabel id="demo-simple-select-outlined-label">Licensa</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    label="Licensa"
                                                    name="driving_category"
                                                    value={element.driving_category}
                                                    onChange={e => handleChangeLicenses(index, "driving_category", e.target.value)}
                                                >
                                                    {drivingLicenses && drivingLicenses.map(license => (
                                                        <MenuItem key={license.driving_category} value={license.driving_category}>
                                                            {license.driving_category}
                                                        </MenuItem>     
                                                    ))}                     
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className={styles.AddNewFormButtons}>
                                        <div className={styles.ButtonText} onClick={() => addNewLicenseHandler()}>
                                            <AddCircleIcon fontSize="small" color="success"/><span>Shto licensë</span>
                                        </div>                                                              
                                        {
                                            index ? (
                                                <div className={styles.ButtonText} onClick={() => removeLicenseHandler(index)}>
                                                    <RemoveCircleIcon className='ms-3' fontSize="small" /><span>Fshije</span>
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className={styles.Divider}></div>
                <div className='align-items-center'>
                    <MobileStepper
                        variant="dots"
                        steps={9}
                        position="static"
                        activeStep={activeStep}
                        nextButton={
                            <Button size="small" type="submit">
                                {activeStep === 8 ? "Regjistrohu dhe apliko" : "Vazhdo"}
                                <KeyboardArrowRight />
                            </Button>
                        }
                        backButton={
                            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                <KeyboardArrowLeft />
                                Kthehu
                            </Button>
                        }
                    />
                </div>
            </form>
        </div>
    );
};

export default Register;