import API from '../api';
import * as c from "../constants";
import countriesCities from "../../assets/json/countries-available.json";
import languages from "../../assets/json/languages.json";
import languagesLevels from "../../assets/json/languages-levels.json";
import computerSkills from "../../assets/json/computer-skills.json";
import skillsLevels from "../../assets/json/skills-levels.json";
import drivingLicenses from "../../assets/json/driving-licences.json";
import businessCategories from "../../assets/json/business-categories.json";

export const getPartnersCompany = () => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.get(`/api/jobshrbee/getPartnerCompanies`, config)
        dispatch({ type: c.GET_PARTNERS, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}

// Right Drawer
export const toggleDrawer = (value) => ({
    type: c.TOGGLE_DRAWER,
    payload: value
})

// SET COUNTRIES
export const setCountries = () => ({
    type: c.SET_COUNTRIES,
    payload: countriesCities
})
// SET LANGUAGES
export const setLanguages = () => ({
    type: c.SET_LANGUAGES,
    payload: languages
})
// SET LANGUAGES LEVELS
export const setLanguagesLevels = () => ({
    type: c.SET_LANGUAGES_LEVELS,
    payload: languagesLevels
})
// SET COMPUTER SKILLS
export const setComputerSkills = () => ({
    type: c.SET_COMPUTER_SKILLS,
    payload: computerSkills
})
// SET SKILLS LEVELS
export const setSkillsLevels = () => ({
    type: c.SET_SKILLS_LEVELS,
    payload: skillsLevels
})
// SET DRIVING LICENSES
export const setDrivinigLicenses = () => ({
    type: c.SET_DRIVING_LICENSES,
    payload: drivingLicenses
})
// SET BUSINESS CATEGORIES
export const setBusinessCategories = () => ({
    type: c.SET_BUSINESS_CATEGORIES,
    payload: businessCategories
})
