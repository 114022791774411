import React, { useState} from 'react';
import { Dialog, IconButton } from '@mui/material';
import { useNavigate } from 'react-router';
import { useDispatch } from "react-redux";
import { applyToJob } from '../../redux/actions/jobseeker/jobs';
import { toast } from "react-toastify";

import CloseIcon from '@mui/icons-material/Close';
import Transition from '../Transition';
import Register from './Register';
import styles from './ApplyJobModal.module.scss';

const ApplyJobModal = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isMobile = window.innerWidth < 756;
    const { open, onClose, job, user } = props;
    const [loading, setLoading] = useState(false);

    const [form, setForm] = useState("");

    const onCloseModal = () => {
        onClose();
        setForm("");
    }

    const submitApplyToJob = () => {
        setLoading(true);
        const data = {
            "job_id": job.job_id,
            "company_id": job.company_id
        };

        dispatch(applyToJob(data)).then(res => {
            toast.success("Aplikimi juaj eshte derguar tek kompania!");
            setLoading(false);
            onClose();
        }).catch(err => {
            console.log("err", err);
            if (err.data === 'no educations') {
                toast.error("Ju duhet te plotesoni edukimin ne profilin tuaj per te aplikuar!");
                onClose();
                navigate("/jobseeker/profile");
            } else {
                toast.error("Diçka shkoi keq. Ju lutem provoni përsëri!");
            }
            setLoading(false);
        });
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onCloseModal}
            aria-describedby="alert-dialog-slide-description"
            maxWidth="md"
        >
            <div style={isMobile ? { width: '370px' } : { width: '800px' }}></div>
            <div style={{ padding: '3px 3px 3px 20px'}}>
                <div className='d-flex align-items-center justify-content-between'>
                    <p className={styles.ApplyModalTitle}>Aplikimi për punë</p>
                    <IconButton onClick={onCloseModal}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </div>
            {!user ? (
                <div style={{ padding: '3px 20px 20px 20px'}}>
                    <div id="alert-dialog-slide-description">
                        {form === "" && (
                            <div>
                                <p className={styles.TextDescription}>Për momentin ju nuk jeni i kyqur, ju ftojmë që të kyqeni për të aplikuar në këtë pozitë. <span onClick={() => navigate('/login')}>Kyqu tani!</span></p>
                                <p className={styles.TextDescription}>Nuk keni llogari? <span onClick={() => setForm('register')}>Regjistrohu tani!</span></p>
                            </div>
                        )}
                        {form === "register" && (
                            <Register job={job} onClose={onCloseModal} />
                        )}
                    </div>
                </div>
            ) : (
                <div style={{ padding: '3px 20px 20px 20px'}}>
                    <p className={styles.TextDescription}>Aplikimi juaj do ti dergohet kompanise!</p>
                    <div className='d-flex justify-content-end'>
                        <button className={styles.ApplyButton} disabled={loading} onClick={() => submitApplyToJob()}>
                            Dergo aplikimin
                        </button>
                    </div>
                </div>
            )}
        </Dialog>
    );
};

export default ApplyJobModal;