export const GET_JOBS = "GET_JOBS";
export const GET_CATEGORY_JOBS = "GET_CATEGORY_JOBS";
export const GET_COUNTRIES_JOBS = "GET_COUNTRIES_JOBS";
export const GET_CITIES_JOBS = "GET_CITIES_JOBS";
export const GET_JOB = "GET_JOB";
export const REGISTER_JOBSEEKER_APPLY = "REGISTER_JOBSEEKER_APPLY";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";

export const SET_OPEN_FILTERS = "SET_OPEN_FILTERS";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_LANGUAGES = "SET_LANGUAGES";
export const SET_LANGUAGES_LEVELS = "SET_LANGUAGES_LEVELS";
export const SET_COMPUTER_SKILLS = "SET_COMPUTER_SKILLS";
export const SET_SKILLS_LEVELS = "SET_SKILLS_LEVELS";
export const SET_DRIVING_LICENSES = "SET_DRIVING_LICENSES";
export const SET_BUSINESS_CATEGORIES = "SET_BUSINESS_CATEGORIES";


// JOBSEEKER
export const GET_JOBSEEKER_PROFILE = "GET_JOBSEEKER_PROFILE";
export const GET_MY_JOB_APPLICATIONS = "GET_MY_JOB_APPLICATIONS";
export const UPDATED_PROFILE = "UPDATED_PROFILE";

// APP 
export const GET_PARTNERS = "GET_PARTNERS";
export const TOGGLE_DRAWER = "TOGGLE_DRAWER";