import axios from 'axios';

const API_URL = process.env.REACT_APP_API_PATH 

// const baseURL = process.env.BASE_URL;
const instance = axios.create({
    baseURL: API_URL
    // baseURL: develop
});
instance.interceptors.request.use(function (config) {
    // Do something before request is sent
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});
instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // alert(error.response.data.message);
    // toastr.error(error.response.data.message);
    return Promise.reject(error.response);
});
export default instance;