import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getJobs, getJobsActiveCategories, getJobsActiveCountries, getJobsActiveCities } from '../../redux/actions/jobs/jobs';
import { CircularProgress, TextField, InputLabel, FormControl, Select, MenuItem } from '@mui/material';
import Pagination from '@mui/lab/Pagination';
import Job from '../../components/job-component/Job';
import styles from './Home.module.scss';

let debounce = null;

const Home = () => {
    const dispatch = useDispatch();
    const jobs = useSelector((state) => state.jobs.jobs);
    const categoryJobs = useSelector((state) => state.jobs.category_jobs);
    const countriesJobs = useSelector((state) => state.jobs.countries_jobs);
    const citiesJobs = useSelector((state) => state.jobs.cities_jobs);
    const filtersDialog = useSelector((state) => state.jobs.filters);

    const [loading, setLoading] = useState(true);
    const [params, setParams] = useState({ page: 1, search: '', category: 'all', country: 'all', city: 'all' });
    const [search, setSearch] = useState("");

    // Clear filters
    const clearFilters = () => {
        setSearch("");
        setParams({ ...params, search: '', category: 'all', country: 'all', city: 'all' });
    }

    // PAGINATION
    const handleChangePagination = (event, value) => {
        setParams({ ...params, page: value });
    };

    // Search
    const searchByName = (e) => {
        setSearch(e.target.value)
        clearTimeout(debounce);
        debounce = setTimeout(() => {
            setParams({ ...params, page: 1, search: e.target.value })
        }, 500);
    }

    useEffect(() => {
        dispatch(getJobs(params)).then((res) => {
            dispatch(getJobsActiveCategories()).then(res => {
                dispatch(getJobsActiveCountries()).then(res => {
                    dispatch(getJobsActiveCities()).then(res => {
                        setLoading(false);
                    }).catch(err => {
                        setLoading(false);
                    })
                }).catch(err => {
                    setLoading(false);
                })
            }).catch(err => {
                setLoading(false);
            })
        }).catch((err) => {
            setLoading(false);
        });
    }, [dispatch, params]);

    return (
        <div>
            {filtersDialog && (
                <div className='container'>
                    <div className={styles.FiltersWrapper}>
                        <div className='row'>
                            <div className="col-md-3">
                                <TextField 
                                    id="outlined-basic" 
                                    size="small" 
                                    variant="outlined" 
                                    onChange={searchByName} 
                                    className="my-2" 
                                    value={search} 
                                    label="Kërko" 
                                    fullWidth={true} 
                                />
                            </div>
                            <div className='col-md-3'>
                                <FormControl variant="outlined" fullWidth={true} className="" size="small">
                                    <InputLabel className="mt-2" id="demo-simple-select-outlined-label">Filtro sipas vendit</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        label="Filtro sipas vendit"
                                        name="country"
                                        value={params.country}
                                        onChange={e => setParams({ ...params, page: 1, country: e.target.value})}
                                        className="mt-2"
                                        required
                                    >
                                        <MenuItem key="all" value="all">
                                            Të gjitha
                                        </MenuItem>  
                                        {countriesJobs && countriesJobs.map(country => (
                                            <MenuItem key={country.country_value} value={country.country_value}>
                                                {country.country_name}
                                            </MenuItem>     
                                        ))}                     
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='col-md-3'>
                                <FormControl variant="outlined" fullWidth={true} className="" size="small">
                                    <InputLabel className="mt-2" id="demo-simple-select-outlined-label">Filtro sipas qytetit</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        label="Filtro sipas qytetit"
                                        name="city"
                                        value={params.city}
                                        onChange={e => setParams({ ...params, page: 1, city: e.target.value})}
                                        className="mt-2"
                                        required
                                    >
                                        <MenuItem key="all" value="all">
                                            Të gjitha
                                        </MenuItem>  
                                        {citiesJobs && citiesJobs.map(city => (
                                            <MenuItem key={city.city_value} value={city.city_value}>
                                                {city.city_name}
                                            </MenuItem>     
                                        ))}                     
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='col-md-3'>
                                <FormControl variant="outlined" fullWidth={true} className="" size="small">
                                    <InputLabel className="mt-2" id="demo-simple-select-outlined-label">Filtro sipas kategorisë</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        label="Filtro sipas kategorisë"
                                        name="category"
                                        value={params.category}
                                        onChange={e => setParams({ ...params, page: 1, category: e.target.value})}
                                        className="mt-2"
                                        required
                                    >
                                        <MenuItem key="all" value="all">
                                            Të gjitha
                                        </MenuItem>  
                                        {categoryJobs && categoryJobs.map(category => (
                                            <MenuItem key={category.category_value} value={category.category_value}>
                                                {category.category_name}
                                            </MenuItem>     
                                        ))}                     
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className={styles.ClearFiltersText} onClick={clearFilters}>
                            {( params.search || params.city !== "all" || params.country !== "all" || params.category !== "all" ) && (
                                <p>Fshij filterat</p>
                            )}
                        </div>
                    </div>
                </div>
            )}

            <div className='container mb-5'>
                {loading && (
                    <div className='text-center mt-5'>
                        <CircularProgress/>
                    </div>
                )}
                <div className='row'>
                    {!loading && jobs && jobs.total > 0 && jobs.data.map(job => (
                        <div className='col-md-3' key={job.job_id}>
                            <Job job={job} />    
                        </div>
                    ))}
                </div>
                {jobs && jobs.total > 0 && jobs.last_page > 1 && (
                    <div className="d-flex justify-content-center mt-5">
                        <Pagination count={jobs.last_page} page={params.page} size="small" onChange={handleChangePagination} color="primary" />
                    </div>
                )}
                {jobs && jobs.total === 0 && (
                    <p className='text-center mt-5'>Nuk u gjet asnje konkurs pune</p>
                )}
            </div>
        </div>
    );
};

export default Home;