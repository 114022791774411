import API from '../../api';
import * as c from "../../constants";

// APPLY TO JOB
export const applyToJob = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.post('/api/jobshrbee/applyToJob', data, config)

        return response
    } catch (error) {
        throw error
    }
}

// MY JOB APPLICATIONS
export const getMyJobApplications = () => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.get('/api/jobshrbee/getMyJobApplications', config);
        dispatch({ type: c.GET_MY_JOB_APPLICATIONS, payload: response.data });

        return response
    } catch (error) {
        throw error
    }
}