import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { Autocomplete, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import styles from "./Forms.module.scss";
import { toast } from "react-toastify";
import { useDispatch } from 'react-redux';
import { getJobSeekerProfile, storeLanguage, updateLanguage } from '../../../../redux/actions/jobseeker/profile';
import { toggleDrawer } from '../../../../redux/actions/app';

const LanguageForm = (props) => {
    const { onFinish, selectedData } = props;
    const dispatch = useDispatch();

    const languagesStore = useSelector((state) => state.app.languages);
    const languagesLevels = useSelector((state) => state.app.languages_levels);

    const [language, setLanguage] = useState(selectedData ? selectedData.language_value : "");
    const [reading, setReading] = useState(selectedData ? selectedData.reading : "");
    const [listening, setListening] = useState(selectedData ? selectedData.listening : "");
    const [speaking, setSpeaking] = useState(selectedData ? selectedData.speaking : "");
    const [writing, setWriting] = useState(selectedData ? selectedData.writing : "");

    const submitData = (e) => {
        e.preventDefault();
        let data = {
            "language_value": language,
            "listening": listening,
            "reading": reading,
            "speaking": speaking,
            "writing": writing
        };
        if(selectedData){
            data = {...data, "id": selectedData.seq_id}
        }
        dispatch(selectedData ? updateLanguage(data) : storeLanguage(data)).then(res => {
            dispatch(getJobSeekerProfile()).then(res => {
                toast.success(selectedData ? "Gjuha u ndryshua me sukses" : "Gjuha u shtua me sukses");
                dispatch(toggleDrawer(false));
                onFinish();
            }).catch(err => {
                toast.error("Diçka shkoi keq. Ju lutem rifreskoni faqen!");
            });
        }).catch(err => {
            toast.error("Diçka shkoi keq. Ju lutem provoni përsëri!");
        });
    }

    return (
        <div>
            <form onSubmit={submitData}>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size='small'
                    options={languagesStore.map((option) => option.name)}
                    value={language}
                    onChange={(e, newValue) => setLanguage(newValue)}
                    renderInput={(params) => <TextField className='my-2' required {...params} label="Zgjedh gjuhën" />}
                />
                <FormControl variant="outlined" className='my-2' fullWidth={true} size="small">
                    <InputLabel id="demo-simple-select-outlined-label">Të lexuarit</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Të lexuarit"
                        name="reading"
                        value={reading}
                        required
                        onChange={e => setReading(e.target.value)}
                    >
                        {languagesLevels && languagesLevels.map(language => (
                            <MenuItem key={language.value} value={language.value}>
                                {language.sq}
                            </MenuItem>     
                        ))}                     
                    </Select>
                </FormControl>
                <FormControl variant="outlined" className='my-2' fullWidth={true} size="small">
                    <InputLabel id="demo-simple-select-outlined-label">Të kuptuarit</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Të kuptuarit"
                        name="listening"
                        required
                        value={listening}
                        onChange={e => setListening(e.target.value)}
                    >
                        {languagesLevels && languagesLevels.map(language => (
                            <MenuItem key={language.value} value={language.value}>
                                {language.sq}
                            </MenuItem>     
                        ))}                     
                    </Select>
                </FormControl>
                <FormControl variant="outlined" className='my-2' fullWidth={true} size="small">
                    <InputLabel id="demo-simple-select-outlined-label">Të folurit</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Të folurit"
                        name="speaking"
                        value={speaking}
                        required
                        onChange={e => setSpeaking(e.target.value)}
                    >
                        {languagesLevels && languagesLevels.map(language => (
                            <MenuItem key={language.value} value={language.value}>
                                {language.sq}
                            </MenuItem>     
                        ))}                     
                    </Select>
                </FormControl>
                <FormControl variant="outlined" className='my-2' fullWidth={true} size="small">
                    <InputLabel id="demo-simple-select-outlined-label">Të shkruarit</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Të shkruarit"
                        name="writing"
                        value={writing}
                        required
                        onChange={e => setWriting(e.target.value)}
                    >
                        {languagesLevels && languagesLevels.map(language => (
                            <MenuItem key={language.value} value={language.value}>
                                {language.sq}
                            </MenuItem>     
                        ))}                     
                    </Select>
                </FormControl>
                <div className='mt-4 text-center'>
                    <button className={styles.SubmitButton}>Ruaj</button>
                </div>
            </form>
        </div>
    );
};

export default LanguageForm;