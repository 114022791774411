import * as c from "../constants";

const initialState = () => {
    return {
        user: [],
        token: '',
    }
}

export const authReducer = (state = initialState(), action) => {
    switch(action.type){
        case c.LOGIN:
            return { ...state, user: action.payload.user_info, token: action.payload.access_token }
        case c.LOGOUT: 
            return { ...state, token: '', user: null }
        case c.UPDATED_PROFILE:
            return { ...state, user: action.payload }
        default:
            return state
    }
}
