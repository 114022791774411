import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';

import { appReducer } from './reducers/app';
import { authReducer } from './reducers/auth';
import { jobsReducer } from './reducers/jobs/jobs';
import { jobseekerReducer } from './reducers/jobseeker/jobseeker';

const mainReducer = combineReducers({
    app: appReducer,
    auth: authReducer,
    jobs: jobsReducer,
    jobseeker: jobseekerReducer
});

const middleware = [thunk, logger]

const tokenFromStorage = localStorage.getItem('token')
    ? JSON.parse(localStorage.getItem('token'))
    : null

const userFromStorage = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null

const initialState = {
    auth: { token: tokenFromStorage, user: userFromStorage }
}


const store = createStore(
    mainReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
)

export default store; 