import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './SingleJob.module.scss';
import moment from 'moment';

const SingleJob = (props) => {
    const navigate = useNavigate();
    const { job, onApplyClick, alreadyApplied } = props;

    const onJobClick = (id) => {
        navigate(`/job/${id}`);
    }

    return (
        <div className={styles.SingleJobWrapper} onClick={() => onJobClick(job.job_id)}>
            <img src={`${process.env.REACT_APP_API_PATH}/images-files/${job.logo_url}`} alt="Company Logo" className={styles.CompanyJobLogo} />
            <div className={styles.Divider}></div>
            <div className={styles.JobDescriptionPositionWrapper}>
                <div>
                    <p className={styles.JobDescriptionLabel}>
                        Pozita
                    </p>
                    <h6 className={styles.JobDescriptionPosition}>
                        {job.job_title}
                    </h6>
                </div>
            </div>
            <div className={styles.Divider}></div>
            <div className={styles.JobDescription}>
                <p className={styles.JobDescriptionLabel}>
                    Kategoria
                </p>
                <h5 className={styles.JobDescriptionValue}>
                    {job.category_name}
                </h5>
            </div>
            <div className={styles.JobDescription}>
                <p className={styles.JobDescriptionLabel}>
                    Lloji punës
                </p>
                <h5 className={styles.JobDescriptionValue}>
                    {job.employment_type_name}
                </h5>
            </div>
            <div className={styles.JobDescription}>
                <p className={styles.JobDescriptionLabel}>
                    Qyteti
                </p>
                <h5 className={styles.JobDescriptionValue}>
                    {job.city_name}
                </h5>
            </div>
            <div className={styles.JobDescription}>
                <p className={styles.JobDescriptionLabel}>
                    Afati për aplikim
                </p>
                <h5 className={styles.JobDescriptionValue}>
                    {moment(job.deadline).format('DD/MM/YYYY')}
                </h5>
            </div>
            <button className={alreadyApplied ? styles.AlreadyApplyButton : styles.ApplyButton} disabled={alreadyApplied} onClick={() => onApplyClick()}>
                {alreadyApplied ? "Keni aplikuar" : "Apliko"}
            </button>
        </div>
    );
};

export default SingleJob;