import API from '../api';
import * as c from "../constants";

export const logoutUser = () => async(dispatch, getState) => {
    try {
        dispatch({ type: c.LOGOUT })

        localStorage.removeItem('user')
        localStorage.removeItem('token')

        return true
    } catch (error) {
        throw error
    }
}

export const login = (email, password) => async(dispatch) => {
    try {

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const localClientId = "2";
        const productionClientId = "2";
        const localClientSecret = "bsJ1J3YPdumqOrT3ABXTvitpEeRuq8E0PzqSYEdZ";
        const productionClientSecret = "JjJVXlRsMcSyFR6qpCdTYZ48cgijl6pWN0bbfky5";

        let data = {
            grant_type: "password",
            client_id: window.location.href.indexOf("localhost") > -1 ? localClientId : productionClientId,
            client_secret: window.location.href.indexOf("localhost") > -1 ? localClientSecret : productionClientSecret,
            username: email,
            password: password,
        };

        const response = await API.post('/oauth/token', data, config);

        localStorage.setItem('user', JSON.stringify(response.data.user_info))
        localStorage.setItem('token', JSON.stringify(response.data.access_token))

        dispatch({ type: c.LOGIN, payload: response.data })

        return response;
    } catch (error) {
        throw error
    }
}

export const register = (data) => async(dispatch) => {
    try {

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const response = await API.post('/api/registerJobSeeker', data, config);

        dispatch({ type: c.REGISTER, payload: response.data })

        return response;
    } catch (error) {
        throw error
    }
}

// REGISTER_JOBSEEKER_APPLY
export const registerJobSeekerAndApply = (data) => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.post('/api/jobshrbee/registerJobSeekerAndApply', data, config)

        return response
    } catch (error) {
        throw error
    }
}

export const registerCompany = (data) => async(dispatch) => {
    try {

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }

        const response = await API.post('/api/registerCompany', data, config);

        return response;
    } catch (error) {
        throw error
    }
}

export const resetPassword = (data) => async(dispatch) => {
    try {

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }

        const response = await API.post('/api/resetPassword', data, config);

        return response;
    } catch (error) {
        throw error
    }
}

export const changePasswordWithCode = (data) => async(dispatch) => {
    try {

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }

        const response = await API.post('/api/changePasswordWithCode', data, config);

        return response;
    } catch (error) {
        throw error
    }
}