import API from '../../api';
import * as c from "../../constants";

// FILTERS
export const setJobsFilters = (value) => ({
    type: c.SET_OPEN_FILTERS,
    payload: value
})

// JOBS 
export const getJobs = (params) => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.get(`/api/jobshrbee/activeJobs?page=${params.page}${params.search !== '' ? `&search=${params.search}` : ``}${params.category !== 'all' ? `&category_value=${params.category}` : ``}${params.country !== 'all' ? `&country=${params.country}` : ``}${params.city !== 'all' ? `&city=${params.city}` : ``}`, config)
        dispatch({ type: c.GET_JOBS, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}

// CATEGORIES JOBS
export const getJobsActiveCategories = () => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.get('/api/jobshrbee/getJobsActiveCategories', config)
        dispatch({ type: c.GET_CATEGORY_JOBS, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}

// COUNTRIES JOBS
export const getJobsActiveCountries = () => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.get('/api/jobshrbee/getJobsActiveCountries', config)
        dispatch({ type: c.GET_COUNTRIES_JOBS, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}

// CITIES JOBS
export const getJobsActiveCities = () => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.get('/api/jobshrbee/getJobsActiveCities', config)
        dispatch({ type: c.GET_CITIES_JOBS, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}

// JOB
export const getJob = (id) => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.post('/api/showAllPublicJobs', {"job_id": id}, config)
        dispatch({ type: c.GET_JOB, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}