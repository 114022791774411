import * as c from "../../constants";

const initialState = () => {
    return {
        profile: null,
        job_applications: null
    }
}

export const jobseekerReducer = (state = initialState(), action) => {
    switch(action.type){
        case c.GET_JOBSEEKER_PROFILE:
            return { ...state, profile: action.payload }
        case c.GET_MY_JOB_APPLICATIONS:
            return { ...state, job_applications: action.payload }
        default:
            return state
    }
}
